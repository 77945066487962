import { useEffect, useState } from 'react';
import './TweetList.css';
import { getUserColor } from '../utils/userColors';

// Helper function to generate random string
const generateRandomString = (length: number) => {
  return Math.random().toString(36).substring(2, 2 + length).toUpperCase();
};

const getColorForUser = (userId: string) => {
  if (!userId) return '#444444';
  return getUserColor(userId);
};

interface TweetListProps {
  selectedUsers: string[];
  selectedTweetIds?: string[];
  tweets?: { [key: string]: any[] };
}

export function TweetList({ selectedUsers, selectedTweetIds, tweets }: TweetListProps) {
  console.log('Selected Tweet IDs:', selectedTweetIds);
  const [updateKey, setUpdateKey] = useState(0);
  const [loading] = useState(false);
  const [allTweets, setAllTweets] = useState<any[]>([]);
  const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (selectedUsers.length === 0 || !tweets) {
      setAllTweets([]);
      return;
    }

    let combinedTweets = Object.values(tweets).flat();
    if (selectedTweetIds && selectedTweetIds.length > 0) {
      combinedTweets = combinedTweets.filter(tweet => selectedTweetIds.includes(tweet.tweet_id));
    }
    setAllTweets([...combinedTweets]);
    setUpdateKey(prev => prev + 1);
  }, [selectedUsers, selectedTweetIds, tweets]);

  const toggleGroup = (authorId: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [authorId]: !prev[authorId]
    }));
  };

  if (loading) {
    return (
      <div className="text-gray-400 text-center py-8">
        Loading tweets...
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="space-y-3">
        {Object.entries(
          [...allTweets].reverse().reduce((groups: { [key: string]: any[] }, tweet) => {
            const group = groups[tweet.author_id] || [];
            group.push(tweet);
            groups[tweet.author_id] = group;
            return groups;
          }, {})
        ).map(([authorId, tweets]) => (
          <div key={authorId} className="bg-gray-800 border border-gray-700 rounded-lg overflow-hidden">
            <button
              onClick={() => toggleGroup(authorId)}
              className="w-full p-4 flex items-center justify-between hover:bg-gray-750 transition-colors"
            >
              <div className="flex items-center gap-2">
                <div
                  className="w-4 h-4 rounded-sm flex-shrink-0"
                  style={{ backgroundColor: getColorForUser(authorId) }}
                />
                <span className="text-gray-300">
                  {tweets.length} tweets from user {authorId}
                </span>
              </div>
              <span className="text-gray-400">
                {expandedGroups[authorId] ? '▼' : '▶'}
              </span>
            </button>

            {expandedGroups[authorId] && (
              <div className="p-4 pt-0 space-y-2">
                {tweets.map((tweet: any, index: number) => (
                  <div
                    key={tweet.tweet_id || `${authorId}-${index}-${updateKey}`}
                    style={{ animationDelay: `${index * 50}ms` }}
                    className="tweet-item bg-gray-850 border border-gray-700 rounded-lg p-4 hover:bg-gray-750 transition-colors"
                  >
                    <div className="flex flex-col space-y-2">
                      <p className="text-gray-300">
                        {tweet.text}
                        <span className="ml-2 text-white font-mono">
                          {generateRandomString(5)}
                        </span>
                      </p>
                      <div className="flex items-center space-x-4">
                        <span className="text-gray-500 text-sm">
                          {new Date(tweet.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TweetList;
